// import {BrowserRouter as Router} from 'react-router-dom';
// import RootRoute from './routes/RootRoute';
// import {AuthProvider} from './providers/AuthProvider';
// import {SenhaProvider} from './providers/SenhaProvider';
import {QueryClient} from '@tanstack/react-query';
// import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
// import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import 'react-toastify/dist/ReactToastify.css';
import {lazy, Suspense} from "react";

const GoogleReCaptchaProvider = lazy(() => import('react-google-recaptcha-v3').then((module) => {
    return {default: module.GoogleReCaptchaProvider}
}));

const RootRoute = lazy(() => import('./routes/RootRoute'));

const AuthProvider = lazy(() => import('./providers/AuthProvider').then((module) => {
    return {default: module.AuthProvider}
}));

const Router = lazy(() => import('react-router-dom').then((module) => {
    return {default: module.BrowserRouter}
}));

const SenhaProvider = lazy(() => import('./providers/SenhaProvider').then((module) => {
    return {default: module.SenhaProvider}
}));

const QueryClientProvider = lazy(() => import('@tanstack/react-query').then((module) => {
    return {default: module.QueryClientProvider}
}));

const ReactQueryDevtools = lazy(() => import('@tanstack/react-query-devtools').then((module) => {
    return {default: module.ReactQueryDevtools}
}));

const queryClient = new QueryClient()

function App() {
    return (
        <div data-testid={'App'}>
            <Suspense>
                <QueryClientProvider client={queryClient}>
                    <Router>
                        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY ?? ''}>
                            <AuthProvider>
                                <SenhaProvider>
                                    <RootRoute/>
                                </SenhaProvider>
                            </AuthProvider>
                        </GoogleReCaptchaProvider>
                    </Router>
                    <ReactQueryDevtools initialIsOpen={false}/>
                </QueryClientProvider>
            </Suspense>
        </div>
    );
}

export default App;
